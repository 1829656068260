import { Spoiler } from "../../../../src/components/Spoiler";
import { Callout } from "../../../../src/components/Callout";
import { Footer, Source, SourceRef } from "../../../../src/components/Footer";
import React from 'react';
export default {
  Spoiler,
  Callout,
  Footer,
  Source,
  SourceRef,
  React
};